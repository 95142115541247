$(document).ready(function () {
    const header = $('#header')
    const footer = $('#footer')
    const sideBar = $('#sidebar')
    const applicationColor = $('#application-color')
    const applicationLogo = $('#application-logo')

    if (applicationColor !== undefined) {
        const color = applicationColor.data('color')
        header.css('background-color', color)
        footer.css('background-color', color)
        sideBar.css('background-color', color)
    }
    if (applicationLogo !== undefined) {
        $('#subject-logo').attr('src', applicationLogo.data('logo-url'))
    }
})

//character counter
$(".count_text").keyup(function () {
    const counter = $(this).parent().next(".counter");
    const max_length = counter.data("maximum-length") - counter.text.length;
    const cur_count = max_length - $(this).val().length;
    if (cur_count <= 0) {
        counter.css('color', 'red');
    } else {
        counter.css('color', 'lightgray');
    }
    counter.text('Characters left: ' + String(cur_count));

});

$('a').click(function (e) {
    const form = $('form')
    if (form.length > 0 && !form[0]?.id?.includes('_search') && $(this).attr('href') !== '#') {
        if (form.serialize() !== form.data('serialize')) {
            if (!confirm('You have unsaved changes! Do you want to continue and lose your changes?')) {
                e.preventDefault();
            }
        }
    }
});

